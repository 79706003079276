<template>
  <div
    :class="$style.videoBox"
    :style="{
      height: size.height,
      width: size.width,
    }"
  >
    <video :id="id" class="video-js" :class="$style.videoContainer">
      <source :src="url" :type="type" />
    </video>
    <div :class="[$style.toolBox]">
      <!-- 播放/停止 -->
      <span :class="$style.iconBtn" @click="handleAutoAction">
        <ali-icon
          type="tc-icon-zanting"
          v-if="autoAction"
          :style="iconStyleObject"
        />
        <ali-icon type="tc-icon-bofang" v-else :style="iconStyleObject" />
      </span>
      <!-- 开启/静音 -->
      <span :class="$style.iconBtn" @click="handleSoundChange">
        <ali-icon
          type="tc-icon-jingyin"
          v-if="!sound"
          :style="iconStyleObject"
        />
        <ali-icon type="tc-icon-jiayinliang" v-else :style="iconStyleObject" />
      </span>
      <!-- 截图 -->
      <span :class="$style.iconBtn" @click="handleCapturePicture">
        <ali-icon type="tc-icon-paizhao" :style="iconStyleObject" />
      </span>
      <!-- 全屏 -->
      <span :class="$style.iconBtn" @click="handleFullScreen">
        <ali-icon type="tc-icon-a-quanping1" :style="iconStyleObject" />
      </span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import AliIcon from '@/components/ali-icon';

@Component({
  components: {
    AliIcon,
  },
})
export default class VideoPlayer extends Vue {
  @Prop({
    required: true,
    type: String,
    // default:
    //   'https://logos-channel.scaleengine.net/logos-channel/live/biblescreen-ad-free/playlist.m3u8',
  })
  url;
  @Prop({ required: false, type: String, default: 'application/x-mpegURL' })
  type;
  @Prop({
    type: Object,
    default: () => ({
      height: '500px',
      width: '100%',
    }),
  })
  size;
  id = `video${parseInt(Math.random() * 1000)}${Date.now()}`;
  player = null;
  iconStyleObject = {
    fontSize: '28px',
    color: 'rgb(255, 255, 255)',
    padding: '0 10px',
  };
  mounted() {
    this.createVideo();
  }
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
  createVideo() {
    const options = {
      autoplay: true, // 设置自动播放
      muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
      preload: 'auto', // 预加载
      controls: false, // 显示播放的控件
    };
    this.player = videojs(this.id, options);
  }
  autoAction = true;
  handleAutoAction() {
    if (this.autoAction) {
      this.player.pause();
      this.autoAction = false;
    } else {
      this.player.play();
      this.autoAction = true;
    }
  }
  sound = false;
  handleSoundChange() {
    const video = document
      .getElementById(this.id)
      .getElementsByTagName('video')[0];
    if (this.sound) {
      video.volume = 0;
      this.sound = false;
      video.muted = true;
    } else {
      video.volume = 1;
      this.sound = true;
      video.muted = false;
    }
  }
  handleFullScreen() {
    const video = document.getElementById(this.id);
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
  }
  handleCapturePicture() {
    const video = document
      .getElementById(this.id)
      .getElementsByTagName('video')[0];
    this.capturePicture(video);
  }
  capturePicture(video) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 900;
    canvas.height = 600;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const name = this.dayjs(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
    const url = canvas.toDataURL('image/jpeg');
    const a = document.createElement('a'); // 生成一个a元素
    a.download = name; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    const event = new MouseEvent('click'); // 创建一个单击事件
    a.dispatchEvent(event); // 触发a的单击事件
  }
}
</script>
<style lang="less" module>
.videoBox {
  display: flex;
  position: relative;
  .videoContainer {
    height: 100%;
    width: 100%;
  }
  &:hover {
    .toolBox {
      display: flex;
    }
  }
  .toolBox {
    display: none;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 999999;
    bottom: 0;
    height: 40px;
    width: 100%;
    box-sizing: content-box;
    background-color: rgba(0, 0, 0, 0.2);
    .iconBtn:nth-of-type(3) {
      margin-left: auto;
    }
  }
}
</style>
<style>
.video-js .vjs-modal-dialog {
  display: none;
}
</style>
